import {format} from 'date-fns'
import {AsyncAction, Action} from 'overmind'
import {
  Site,
  Me,
  Chain,
  OrgLevel,
  OrgLevelType,
  ErrorView,
  ChainPayload,
  Notification,
  NotificationType,
  Location
} from './state'
import {findIndex} from 'lodash'
import * as Sentry from '@sentry/browser'
import {
  AnyTaskData,
  AlarmSetting,
  TaskGroup,
  FileUploadData,
  FileDownloadData,
  AssetData,
  AssetPayload,
  FileUploadPayload,
  EquipmentType,
  StorageType,
  Equipment,
  PostEquipment,
  PossibleEvent,
  DishwasherType,
  CoolerType,
  DevicesForSite
} from './rest'
import {GetApplianceSettingParams} from '../state/settings/appliances/state'
import {LayoutError, normalizeSites} from '../config/utils'
import {getHumanizedDate, getHumanizedDateWithTime, getLocale} from '../Components/Atoms/Utils'
import {deepCopy} from 'overmind/lib/utils'
import i from 'i18next'
import {v4 as uuid} from 'uuid'
import {websocketClient, WebsocketMessage, WebsocketMessageType} from './websocket'
import {CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js'
import Auth from '@aws-amplify/auth'
import {PAGE_LIMIT_LATE} from '../pages/tasks'
import {ALARM_PAGE_LIMIT} from '../pages/alarms'
import {isSiteSuspended} from '../pages/settings/utils'

export const API_VERSION = 10
const REFRESH_SESSION_TASK_INTERVAL_MS = 1000 * 60 * 30 // 30 minutes

export interface EventMessage {
  eventType: string
  site: string
}

/**
 * General
 */

export const checkApiMatch: AsyncAction = async ({state, actions, effects}) => {
  try {
    const status = await effects.appStatusApi.checkStatus()
    if (status.minApiVersion && status.minApiVersion > API_VERSION) {
      return state.appStateMachine.ERROR(() => {
        state.errorView = ErrorView.UPDATE_REQUIRED
      })
    }
    if (status.apiVersion < API_VERSION) {
      return state.appStateMachine.ERROR(() => {
        state.errorView = ErrorView.BACKEND_UPDATING
      })
    } else {
      await actions.authenticate()
    }
  } catch (e) {
    return state.appStateMachine.ERROR(() => {
      state.errorView = ErrorView.SYSTEM_DOWN
    })
  }
}

export const setOnlineStatus: Action<boolean> = ({state}, value) => {
  console.debug(`Online status: ${value}`)
  state.isOnline = value
}

export const reloadApp: AsyncAction = async ({state, actions}) => {
  await actions.checkApiMatch()
  return state.appStateMachine.LOADING(async () => {
    try {
      actions.updateSelectedChainId()

      await actions.updateMe()
      if (state.me?.accessRights.superuser === false) {
        await actions.updateUi()
      } else if (!!state.selectedChainId) {
        await actions.updateUi()
      }
      if (state.me?.accessRights.superuser === true) {
        await actions.updateDataForSuperuser()
      }

      return state.appStateMachine.READY()
    } catch (err) {
      console.log('reloadApp error', JSON.stringify(err as any))

      return state.appStateMachine.ERROR(() => {
        const error = err as any
        if (error && error?.response && (error?.response.status === 401 || error?.response.status === 403)) {
          state.errorView = ErrorView.UNAUTHORIZED
        } else {
          state.errorView = ErrorView.DEFAULT
        }
      })
    }
  })
}
export const updateUi: AsyncAction = async ({state, actions, effects}) => {
  state.initialData = await effects.tasksApi.getInitialData()
  await actions.updateAllData()
}
export const updateSite: AsyncAction<Site> = async ({state, actions}, site: Site) => {
  state.site = {...site}
  localStorage.setItem('site', JSON.stringify(site))
  const orgLevel = {
    id: site.id,
    name: site.name,
    type: OrgLevelType.SITE
  }
  state.orgLevel = orgLevel
  localStorage.setItem('orgLevel', JSON.stringify(orgLevel))

  await actions.updateAllData()
}
export const updateOrgLevel: AsyncAction<OrgLevel> = async ({state, actions, effects}, orgLevel: OrgLevel) => {
  // need to update site
  if (orgLevel.type === OrgLevelType.SITE) {
    if (!state.me) {
      const me = (await effects.userApi.getMeData()) as Me
      state.me = me
    }
    const site = normalizeSites(state.chainsById[state.selectedChainId!]).find(s => {
      return s.id === orgLevel.id
    })
    if (site) {
      state.site = {...site}
      localStorage.setItem('site', JSON.stringify(site))
    }
  }
  // update organisation level
  state.orgLevel = {...orgLevel}
  localStorage.setItem('orgLevel', JSON.stringify(orgLevel))

  await actions.updateAllData()
  await actions.notifyIfSiteSuspended()
}
export const updateAllData: AsyncAction = async ({state, actions}) => {
  let promises = [
    actions.getEquipmentTypes(),
    actions.getStorageTypes(),
    actions.getWasteScaleCategories(),
    actions.getDishwasherTypes(),
    actions.getCoolerTypes(),
    actions.getNewsTickers()
  ]

  if (state.me) {
    // Todo! better way to check user role?
    const orgLevels = Object.keys(state.me!.accessRights.write) as Array<
      'brands' | 'locations' | 'chains' | 'groups' | 'sites'
    >
    const writableIds = orgLevels.flatMap(level => state.me!.accessRights.write[level])
    const isBasicUser = writableIds.length === 0

    if (!isBasicUser) {
      promises.push(actions.getAlarmSettings())
    }
  }

  await Promise.all(promises)
}
// updateDataForSuperuser: update data needed for superuser landing page
export const updateDataForSuperuser: AsyncAction = async ({state, actions}) => {
  let promises = [actions.getNewsTickers(), actions.getGatewaysWithSensorsForChain(), actions.getSensorModels()]
  await Promise.all(promises)
}

export const handleWebsocketMessage: AsyncAction<MessageEvent> = async ({state, actions}, event): Promise<any> => {
  let promises = [] as Promise<any>[]
  const data: WebsocketMessage = JSON.parse(event.data)
  if (data.type !== WebsocketMessageType.pong) {
    console.log('Websocket message', data)
  }

  // events that do not require selectedChainId, e.g. things on superuser landing page
  if (data.type === WebsocketMessageType.newsTicker) {
    promises = [actions.getNewsTickers()]
  }

  // if chain not selected (e.g. superuser chain selection view) > skip these events
  if (state.selectedChainId && data.siteId && state.site && state.site.id === data.siteId) {
    const siteId = state.site!.id
    switch (data.type) {
      case WebsocketMessageType.alarm:
        // only refresh data when use is currently on the certain page
        if (window.location.pathname === '/alarms') {
          promises = [
            actions.v1.alarms.getAlarmsForSiteWithPagination({
              siteId,
              showLoadingIndicator: false,
              options: {
                limit: ALARM_PAGE_LIMIT,
                offset: 0
              }
            })
          ]
        }
        if (window.location.pathname === '/') {
          promises = [actions.v1.home.site.getInitialHomeData({siteId, onlyRefreshData: true})]
        }
        break
      case WebsocketMessageType.task:
      case WebsocketMessageType.event:
        if (window.location.pathname === '/tasks') {
          promises = [actions.v1.tasks.getTasksForSite({siteId, onlyRefreshData: true, limitLate: PAGE_LIMIT_LATE})]
        }
        if (window.location.pathname === '/') {
          promises = [actions.v1.home.site.getInitialHomeData({siteId, onlyRefreshData: true})]
        }
        break
      case WebsocketMessageType.cycleLog:
        break
      default:
        console.warn('Unknown websocket message type', data.type)
        break
    }
  }

  if (promises) {
    await Promise.all(promises)
  }
}

/**
 * Error handler for websocket. Refreshes ID token in case error is caused by failed authentication.
 * @param param0
 * @param _event
 */
export const handleWebsocketError: AsyncAction<Event> = async ({effects}, _event) => {
  const token = await effects.authApi.getToken()
  websocketClient.setIdToken(token)
}

export const initializeWebsocket: AsyncAction = async ({actions, effects}): Promise<any> => {
  try {
    const token = await effects.authApi.getToken()
    websocketClient.setIdToken(token)
    websocketClient.onMessage = actions.handleWebsocketMessage
    websocketClient.onError = actions.handleWebsocketError
    websocketClient.connect()
  } catch (err) {
    console.error('Websocket initialization failed', err)
  }
}

/**
 * User
 */

export const authenticate: AsyncAction = async ({state, actions, effects}) => {
  console.log('authenticating')

  return state.appStateMachine.AUTHENTICATING(async () => {
    // Stop refresh session task if needed
    if (typeof state.refreshSessionTaskId === 'number') {
      window.clearInterval(state.refreshSessionTaskId)
      state.refreshSessionTaskId = null
    }
    const currentUser = await effects.authApi.getCurrentUser()

    if (currentUser) {
      console.log('got current user and initialize sentry with user uuid')
      Sentry.setUser({id: currentUser?.getUsername()})
      await actions.initializeWebsocket()

      return state.appStateMachine.AUTHENTICATED(() => {
        console.log('setting current user')

        state.currentUser = currentUser

        // Start refresh session task
        state.refreshSessionTaskId = window.setInterval(async () => {
          try {
            const refreshedSession = await refreshSession()
            const refreshedIdToken = refreshedSession.getIdToken()?.getJwtToken()
            if (!refreshedIdToken) {
              throw new Error('authenticate: could not get ID token while refreshing session')
            }
            websocketClient.setIdToken(refreshedIdToken)
            websocketClient.connect()
          } catch (err) {
            console.error(err)
          }
        }, REFRESH_SESSION_TASK_INTERVAL_MS)
      })
    } else return state.appStateMachine.UNAUTHENTICATED()
  })
}

const refreshSession = (): Promise<CognitoUserSession> => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('refreshSession: refreshing session')
      // Use Auth.currentAuthenticatedUser() instead of state.currentUser because otherwise currentUser.refreshSession() fails with localStorage error
      const currentUser: CognitoUser = await Auth.currentAuthenticatedUser()
      const refreshToken = currentUser.getSignInUserSession()?.getRefreshToken()
      if (!refreshToken) {
        return reject(new Error('refreshSession: could not get refreshToken while refreshing session'))
      }
      currentUser.refreshSession(refreshToken, (err, session: CognitoUserSession) => {
        if (err) {
          return reject(err)
        } else {
          console.log('refreshSession: session refreshed')
          return resolve(session)
        }
      })
    } catch (err) {
      return reject(err)
    }
  })
}

export const login: AsyncAction<{email: string; password: string}> = async (
  {state, actions, effects},
  {email, password}
) => {
  state.authenticationError = undefined
  state.isAuthenticating = true
  state.forcePasswordResetUser = undefined
  try {
    const user = await effects.authApi.authenticate(email, password)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      console.log('NEW_PASSWORD_REQUIRED after authenticate', user)
      user.email = email // needed later in ForceResetPassword.tsx for the kludge cognito bufgix
      state.forcePasswordResetUser = user
    } else if (user.challengeName === 'SMS_MFA') {
      console.log('Requiring MFA authentication')
      const code = window.prompt(
        'Multi-factor authentication required: Please enter the code sent to your mobile phone'
      )
      try {
        await effects.authApi.mfaAuthenticate(user, code as string)
        await actions.reloadApp()
      } catch (e) {
        console.error('mfa authentication error', JSON.stringify(e, null, 2))
        state.authenticationError = 'invalidMfa'
      }
    } else {
      await actions.reloadApp()
    }
  } catch (err) {
    const e = err as any
    console.error('authentication error', JSON.stringify(e, null, 2))
    if (e.code === 'NotAuthorizedException') {
      if (e.message === 'Password attempts exceeded') {
        // too many wrong password attempts lately
        state.authenticationError = 'tooManyAttempts'
      } else if (e.message === 'Temporary password has expired and must be reset by an administrator.') {
        // user and password has not been used within timeframe of expiry
        state.authenticationError = 'userTemporaryPasswordExpired'
      } else {
        // wrong email/password
        state.authenticationError = 'invalidCredentials'
      }
    } else {
      state.authenticationError = 'unknownError'
    }
  }
  state.isAuthenticating = false
}

export const cancelForcePasswordReset: AsyncAction = async ({state}) => {
  state.forcePasswordResetUser = undefined
  state.authenticationError = undefined
}

export const completeForceResetPassword: AsyncAction<{newPassword: string; email: string}> = async (
  {state, effects},
  {newPassword, email}
) => {
  const cognitoUser = state.forcePasswordResetUser
  if (cognitoUser) {
    await effects.authApi.completeForceResetPassword(cognitoUser, newPassword, email)
  } else {
    throw new Error('completeForceResetPassword: missing state.forcePasswordResetUser')
  }
}

export const logout: AsyncAction = async ({state, effects}) => {
  return state.appStateMachine.UNAUTHENTICATING(async () => {
    try {
      await effects.authApi.logout()
      websocketClient.disconnect(false)

      return state.appStateMachine.UNAUTHENTICATED(() => {
        localStorage.clear()
        state.currentUser = null
        state.me = undefined
        state.site = undefined
      })
    } catch (e) {
      return state.appStateMachine.AUTHENTICATED()
    }
  })
}

export const forgotPassword: AsyncAction<{email: string}> = async ({effects}, {email}) => {
  await effects.authApi.forgotPassword(email)
}

export const resetPassword: AsyncAction<string> = async ({state, actions, effects}, id: string) => {
  await i.loadNamespaces('msg')
  try {
    await effects.userApi.resetPassword(id)
    actions.addNotification({
      id: uuid(),
      type: NotificationType.INFO,
      title: i.t('msg:passwordSent', 'Password sent'),
      description: i.t('msg:passwordSentDescription', 'Reset successful'),
      visible: true,
      hideAfterDelay: 1500
    })
  } catch (e) {
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('msg:passwordResetError', 'Reset error'),
      description: i.t('msg:passwordResetErrorDescription', 'Password reset unsuccessful'),
      visible: true,
      hideAfterDelay: 6000
    })
  }
}

export const confirmPassword: AsyncAction<{email: string; password: string; code: string}> = async (
  {effects, actions},
  {email, password, code}
) => {
  console.debug({email}, {password}, {code})
  try {
    await effects.authApi.confirmPassword(email, password, code)
  } catch (e) {
    // Don't throw original error for security reasons
    await i.loadNamespaces('msg')
    actions.addNotification({
      id: uuid(),
      type: NotificationType.ERROR,
      title: i.t('msg:passwordConfirmError', 'Invalid confirmation code'),
      description: i.t('msg:passwordConfirmErrorDescription', ''),
      visible: true,
      hideAfterDelay: 6000
    })
    throw new LayoutError('Invalid confirmation code')
  }
}

export const changePassword: AsyncAction<
  {currentPassword: string | undefined; newPassword: string | undefined},
  string
> = async ({effects}, {currentPassword, newPassword}): Promise<string> => {
  return effects.authApi.changePassword(currentPassword as string, newPassword as string)
}

const searchLocationsForSite = (locations: Location[]) => {
  return locations.flatMap(location => location.sites).find(site => Boolean(site))
}

const searchValidSiteFromChain = (chain: Chain) => {
  for (const brand of chain.brands) {
    for (const group of brand.locationGroups) {
      let site = searchLocationsForSite(group.locations)
      if (site) {
        return site
      }
    }
  }
  return undefined
}

export const updateMe: AsyncAction = async ({state, effects, actions}) => {
  const me = (await effects.userApi.getMeData()) as Me
  await i.changeLanguage(me.user.language, () => {
    actions.notifyIfSiteSuspended()
  })
  state.me = me
  if (state.me.accessRights.superuser) {
    state.chains = (await effects.adminApi.getChains()).items
  } else {
    state.chains = deepCopy(me.org.chains)
    const id = me.org.chains[0].id
    localStorage.setItem('selectedChainId', id)
    state.selectedChainId = id
    actions.updateSelectedChainId()
  }
  if (state.selectedChainId) {
    const chain = state.chainsById[state.selectedChainId!] as Chain
    const site =
      localStorage.getItem('site') !== null
        ? JSON.parse(localStorage.getItem('site')!)
        : searchValidSiteFromChain(chain)
    if (!Boolean(site)) {
      console.log(`Chain ${chain.name} has no sites`)
    }
    state.site = deepCopy(site)

    const orgLevel =
      localStorage.getItem('orgLevel') !== null
        ? JSON.parse(localStorage.getItem('orgLevel')!)
        : normalizeSites(state.chainsById[state.selectedChainId!]).length > 1
        ? {id: state.chainsById[state.selectedChainId!].id, name: state.chains[0].name, type: OrgLevelType.CHAIN}
        : {id: site.id, name: site.name, type: OrgLevelType.SITE}
    state.orgLevel = orgLevel
  }
}
export const downloadUserList: AsyncAction = async ({effects}) => {
  try {
    const response = await effects.userApi.downloadUserList()
    if (response.body.length == 0 && !response.isBase64Encoded) {
      return
    }
    const base64File = response.body
    const binaryData = atob(base64File) // Decode base64 to binary string

    // Convert binary string to a Uint8Array
    const byteArray = new Uint8Array(binaryData.length)
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i)
    }
    const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    const url = window.URL.createObjectURL(blob)

    // Create an anchor tag
    const link = document.createElement('a')
    link.href = url

    // Set the filename for the download
    const filename = i.t('admin:userList', 'user_list.xlsx')
    link.download = filename

    // Trigger the download by programmatically clicking the link
    link.click()

    // Revoke the object URL after the download
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading user list:', error)
    throw error
  }
}

export const notifyIfSiteSuspended: AsyncAction = async ({state, actions}) => {
  const getSiteSuspendedInfo = async (site: Site) => {
    const suspendedPeriods = await actions.v1.settings.suspendedPeriods.getSiteSuspendedPeriods({
      siteId: site.id,
      showLoadingIndicator: false
    })
    const now = new Date()
    return suspendedPeriods ? isSiteSuspended(suspendedPeriods, now) : false
  }

  // add site suspended notification if current site is suspended
  const showSuspendedNotification =
    state.site && state.orgLevel!.type === OrgLevelType.SITE ? await getSiteSuspendedInfo(state.site) : false
  if (showSuspendedNotification) {
    console.log('loading namespace msg')
    await i.loadNamespaces('msg')
    console.log('loaded namespace msg')
    actions.addNotification({
      id: 'fixed:siteSuspended', // use fixed id so there will be no multiple instance of the same notification
      type: NotificationType.INFO,
      title: i.t('msg:siteSuspended', 'This site is suspended'),
      description: i.t('msg:siteSuspendedContact', 'Contact your organisation admin to open the site.'),
      visible: true
    })
  } else {
    // toggle site suspended notification
    if (state.notifications['fixed:siteSuspended']) {
      state.notifications['fixed:siteSuspended'].visible = false
    }
  }
}

export const updateAccountSettings: AsyncAction<any> = async ({state, effects, actions}, payload: any) => {
  await effects.userApi.updateUser(payload.id, payload)
  // const me = (await effects.userApi.getMeData()) as Me
  // state.me = me
  await actions.updateMe()
}

/**
 * Chains
 */

export const getChains: AsyncAction = async ({state, effects}) => {
  console.time('updateChains')
  const data = await effects.adminApi.getChains()
  state.chains = data.items
  console.timeEnd('updateChains')
}
export const getChain: AsyncAction<string, Chain> = async ({state, effects}, id: string) => {
  const data = await effects.adminApi.getChain(id)
  return data
}
export const getAromiLinkForChain: AsyncAction<string, string> = async ({state, effects}, id: string) => {
  return await effects.userApi.getAromiLinkForChain(id)
}
export const createChain: AsyncAction<ChainPayload> = async ({actions, effects}, payload) => {
  await effects.adminApi.createChain(payload)
  await actions.getChains()
}
export const createOrganization: AsyncAction = async ({actions, effects}) => {
  await effects.adminApi.createOrganization()
  await actions.getChains()
}
export const deleteChain: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.adminApi.deleteChain(id)
  await actions.getChains()
}
export const updateChain: AsyncAction<Chain> = async ({actions, effects}, payload) => {
  await effects.adminApi.updateChain(payload)
  await actions.getChain(payload.id)
}

export const resetChain: AsyncAction = async ({actions}) => {
  localStorage.removeItem('selectedChainId')
  localStorage.removeItem('orgLevel')
  localStorage.removeItem('site')
  // actions.updateSelectedChainId()
  window.location.reload()
}
export const updateSelectedChainId: Action = ({state}) => {
  state.selectedChainId = localStorage.getItem('selectedChainId') || undefined
}

/**
 * Task Groups
 */

export const updateTaskGroups: AsyncAction = async ({state, effects}) => {
  const taskGroups = await effects.taskGroupsApi.getTaskGroups()
  state.initialData.taskGroups = taskGroups
}
export const createTaskGroup: AsyncAction<Partial<TaskGroup>> = async ({actions, effects}, payload) => {
  await effects.taskGroupsApi.createTaskGroup(payload)
  await actions.updateTaskGroups()
}
export const updateTaskGroup: AsyncAction<TaskGroup> = async ({actions, effects}, payload) => {
  await effects.taskGroupsApi.updateTaskGroup(payload)
  // TODO remove this update once we don't use intialData.taskGroups anywhere
  await actions.updateTaskGroups()
}
export const deleteTaskGroup: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.taskGroupsApi.deleteTaskGroup(id)
  await actions.updateTaskGroups()
}

/**
 * Assets
 */

export const createAsset: AsyncAction<AssetPayload, AssetData> = async (
  {state, effects, actions},
  payload: AssetPayload
): Promise<AssetData> => {
  const asset = await effects.assetApi.add(payload, state.site!)
  return asset
}

export const createUploadUrl: AsyncAction<FileUploadPayload, FileUploadData> = async (
  {state, effects},
  payload: FileUploadPayload
): Promise<FileUploadData> => {
  return await effects.assetApi.createUploadUrl(payload, state.site!)
}

export const getDownloadUrl: AsyncAction<string, FileDownloadData> = async (
  {effects},
  id: string
): Promise<FileDownloadData> => {
  return await effects.assetApi.getDownloadUrl(id)
}

/**
 * Tasks & events
 */
export const createTask: AsyncAction<any> = async ({state, actions, effects}, payload: any) => {
  await effects.tasksApi.add(payload, state.site!)
  await actions.updateUi()
}
export const updateTask: AsyncAction<any> = async ({state, actions, effects}, payload: any) => {
  await effects.tasksApi.update(payload.id, state.site!, payload)
  await actions.updateUi()
}
export const deleteTask: AsyncAction<string> = async ({state, actions, effects}, id: string) => {
  await effects.tasksApi.delete(id, state.site!)
  await actions.updateUi()
}

export const handleEventStateUpdate: AsyncAction<PossibleEvent> = async ({state, actions}, e) => {
  if (e.status === 'DONE') {
    await i.loadNamespaces('msg')
    actions.addNotification({
      id: uuid(),
      type: NotificationType.INFO,
      icon: 'OK',
      title: i.t('msg:taskCompleted', 'Task completed!'),
      visible: true,
      hideAfterDelay: 1500
    })
  }
}

export const getTask: AsyncAction<string, AnyTaskData> = async ({state, effects}, id: string) => {
  const data = await effects.tasksApi.getTask(id, state.site!)
  console.log('task data: ', data)
  return data
}

/**
 * Alarm Settings
 * */

export const getAlarmSettings: AsyncAction = async ({state, effects}) => {
  console.time('getAlarmSettings')
  const data = await effects.alarmsApi.getAlarmSettings()
  state.alarmSettings = data.items
  console.timeEnd('getAlarmSettings')
}
export const getAlarmSetting: AsyncAction<string, AlarmSetting> = async ({state, effects}, id: string) => {
  const data = await effects.alarmsApi.getAlarmSetting(id)
  return data
}
export const createAlarmSetting: AsyncAction<any> = async ({state, actions, effects}, payload) => {
  await effects.alarmsApi.createAlarmSetting(payload)
  await actions.getAlarmSettings()
}
export const deleteAlarmSetting: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.alarmsApi.deleteAlarmSetting(id)
  await actions.getAlarmSettings()
}
export const updateAlarmSetting: AsyncAction<any> = async ({actions, effects}, payload) => {
  await effects.alarmsApi.updateAlarmSetting(payload)
  await actions.getAlarmSettings()
}

export const localHumanizedDateWithTime: Action<Date, string> = ({state}, date) => {
  return getHumanizedDateWithTime(date, state.site!.locale)
}
export const localHumanizedDate: Action<Date, string> = ({state}, date) => {
  return getHumanizedDate(date, state.site!.locale)
}
export const localTimeString: Action<Date, string> = ({state}, date) => {
  try {
    return format(date, 'p', {locale: getLocale(state.site?.locale)})
  } catch (e) {
    return ''
  }
}
const CallbackReloadPage = () => {
  console.debug('Callback: reload page')
  window.location.reload()
}
export const setAppCurrentVersion: AsyncAction<string> = async ({state, actions}, value) => {
  const commitHashFromStartup = process.env.REACT_APP_COMMIT_HASH
  console.log(`setAppCurrentVersion: ${value} / ${commitHashFromStartup}`)
  if (commitHashFromStartup) {
    if (value.trim() !== commitHashFromStartup.trim()) {
      state.appUpdated = true
      await i.loadNamespaces('msg')
      actions.addNotification({
        id: 'fixed:appUpdated', // use fixed id so there will be no multiple instance of the same notification
        type: NotificationType.INFO,
        title: i.t('msg:updateAvailable', "There's an update for CGI Chefstein!"),
        description: i.t('msg:refreshBrowser', 'Please refresh your browser window.'),
        visible: true,
        action: {
          text: i.t('common:buttons.refresh', 'Refresh'),
          action: () => CallbackReloadPage,
          showClose: true
        }
      })
    }
  }
}

/**
 * Equipments
 */
export const getEquipment: AsyncAction<GetApplianceSettingParams, Equipment> = async (
  {state, effects},
  params: GetApplianceSettingParams
) => {
  const data = await effects.equipmentApi.getEquipment(params.applianceId, state.site!, params.siteId)
  const index = findIndex(state.equipments, ['id', data.id])
  if (index >= 0) {
    state.equipments[index] = data
  } else {
    state.equipments = [data]
  }
  return data
}
export const createEquipment: AsyncAction<Partial<PostEquipment>> = async ({state, actions, effects}, payload) => {
  const site = payload.site?.id ? payload.site : state.site!
  await effects.equipmentApi.createEquipment(payload, site)
  await actions.getSensorChannels()
}
export const deleteEquipment: AsyncAction<GetApplianceSettingParams> = async (
  {actions, effects},
  params: GetApplianceSettingParams
) => {
  await effects.equipmentApi.deleteEquipment(params.applianceId, params.siteId)
  await actions.getSensorChannels()
}

export const getEquipmentTypes: AsyncAction = async ({state, effects}) => {
  const data = await effects.equipmentApi.getEquipmentTypes()
  state.equipmentTypes = data.items
}
export const getEquipmentType: AsyncAction<string, EquipmentType> = async ({state, effects}, id: string) => {
  const data = await effects.equipmentApi.getEquipmentType(id)
  return data
}
export const createEquipmentType: AsyncAction<Partial<EquipmentType>> = async ({actions, effects}, payload) => {
  await effects.equipmentApi.createEquipmentType(payload)
  await actions.getEquipmentTypes()
}
export const deleteEquipmentType: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.equipmentApi.deleteEquipmentType(id)
  await actions.getEquipmentTypes()
}
export const updateEquipmentType: AsyncAction<EquipmentType> = async ({actions, effects}, payload) => {
  await effects.equipmentApi.updateEquipmentType(payload)
  await actions.getEquipmentTypes()
}

export const getStorageTypes: AsyncAction = async ({state, effects}) => {
  const data = await effects.equipmentApi.getStorageTypes()
  state.storageTypes = data.items
}
export const getStorageType: AsyncAction<string, StorageType> = async ({state, effects}, id: string) => {
  const data = await effects.equipmentApi.getStorageType(id)
  return data
}
export const createStorageType: AsyncAction<Partial<StorageType>> = async ({actions, effects}, payload) => {
  await effects.equipmentApi.createStorageType(payload)
  await actions.getStorageTypes()
}
export const deleteStorageType: AsyncAction<string> = async ({actions, effects}, id) => {
  await effects.equipmentApi.deleteStorageType(id)
  await actions.getStorageTypes()
}
export const updateStorageType: AsyncAction<StorageType> = async ({actions, effects}, payload) => {
  await effects.equipmentApi.updateStorageType(payload)
  await actions.getStorageTypes()
}

export const getDishwasherTypes: AsyncAction = async ({state, effects}) => {
  const data = await effects.equipmentApi.getDishwasherTypes()
  state.dishwasherTypes = data.items
}
export const updateDishwasherType: AsyncAction<DishwasherType> = async ({actions, effects}, payload) => {
  await effects.equipmentApi.updateDishwasherType(payload)
  await actions.getDishwasherTypes()
}

export const getCoolerTypes: AsyncAction = async ({state, effects}) => {
  const data = await effects.equipmentApi.getCoolerTypes()
  state.coolerTypes = data.items
}
export const updateCoolerType: AsyncAction<CoolerType> = async ({actions, effects}, payload) => {
  await effects.equipmentApi.updateCoolerType(payload)
  await actions.getCoolerTypes()
}

export const getSensorChannels: AsyncAction = async ({state, effects}) => {
  const data = await effects.equipmentApi.getSensorChannels(state.site!)
  state.sensorChannels = data.items
}
export const getFreeSensorChannels: AsyncAction = async ({state, effects}) => {
  const data = await effects.equipmentApi.getFreeSensorChannels(state.site!)
  state.freeSensorChannels = data.items
}
export const getWasteScaleCategories: AsyncAction = async ({state, effects}) => {
  const data = await effects.equipmentApi.getWasteScaleCategories()
  state.wasteScaleCategories = data.items
}

export const getNewsTickers: AsyncAction = async ({state, effects}) => {
  const newsTickers = await effects.adminApi.getNewsTickers()
  state.newsTickers = newsTickers.items
}

export const getGatewaysWithSensorsForChain: AsyncAction = async ({state, effects}) => {
  const response = await effects.adminApi.getGatewaysAndSensorsForChain()
  state.gatewaysWithSensorsForSites = response
}

export const getGatewaysWithSensorsForSite: AsyncAction<string> = async ({state, effects}, siteId) => {
  const response = await effects.adminApi.getGatewaysAndSensorsForSite(siteId)
  const gatewaysWithSensorsForSite: DevicesForSite = {
    siteId,
    devices: response
  }
  const index = state.gatewaysWithSensorsForSites.findIndex(devicesForSite => devicesForSite.siteId === siteId)
  if (index >= 0) {
    state.gatewaysWithSensorsForSites[index] = gatewaysWithSensorsForSite
  } else {
    state.gatewaysWithSensorsForSites = [...state.gatewaysWithSensorsForSites, gatewaysWithSensorsForSite]
  }
}

export const getSensorModels: AsyncAction = async ({state, effects}) => {
  const response = await effects.adminApi.getSensorModels()
  state.sensorModels = response
}

export const setNewsTickerVisible: Action<boolean> = ({state}, visible) => {
  state.newsTickerVisible = visible
}

export const addNotification: Action<Notification> = ({state}, notification) => {
  // todo save to local storage?
  console.log('adding notification')

  state.notifications[notification.id] = notification
}

export const addNotificationGenericFormError: AsyncAction = async ({actions}) => {
  await i.loadNamespaces('msg')
  actions.addNotification({
    id: uuid(),
    type: NotificationType.ERROR,
    title: i.t('msg:genericFormError', 'Cannot save the data'),
    description: i.t('msg:genericFormErrorDescription', 'Please check your input and retry.'),
    visible: true,
    hideAfterDelay: 6000
  })
}

export const hideNotification: Action<Notification> = ({state, actions}, notification) => {
  // todo save to local storage?
  state.notifications[notification.id] = {...notification, visible: false}
  setTimeout(() => {
    actions.removeNotification(notification)
  }, 700) // hiding animation is currently 500ms so let's wait until that finishes
}

export const removeNotification: Action<Notification> = ({state}, notification) => {
  if (state.notifications.hasOwnProperty(notification.id)) {
    delete state.notifications[notification.id]
  }
}

export const hasUserWriteRights: Action<string, boolean> = ({state}, id) => {
  const chains = state.me?.accessRights.write.chains.includes(id)
  const brands = state.me?.accessRights.write.brands.includes(id)
  const groups = state.me?.accessRights.write.groups.includes(id)
  const locations = state.me?.accessRights.write.locations.includes(id)
  const sites = state.me?.accessRights.write.sites.includes(id)
  return !!chains || !!brands || !!groups || !!locations || !!sites
}

export const getEmbeddedUrl: AsyncAction<boolean, string> = async ({effects}, isTestDashboard) => {
  try {
    const data = await effects.dashboardApi.getDashboardData(isTestDashboard)
    return data.embedUrl
  } catch (e) {
    console.error(e)
    return ''
  }
}
